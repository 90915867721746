@import "vars.css";

.footer {
    padding: 2.5rem 0 0 0;
    background: url('../img/footer.jpg');
    background-position: center;
    background-size: cover;
    color: var(--color-brown);
    font-family: var(--font-family-sans-serif-lato);
    font-size: 1.1rem;

    a{
        color: #fff;
        text-decoration: underline;
    }

    .nav-link{
        padding: 0.5rem 0;
    }
}

.container-footer {
    @media (min-width: 940px) {
        display: flex;
        justify-content: space-between;
    }
}

.footer-logo {
    text-align: center;
    @media (min-width: 940px) {
        text-align: left;
    }

    .logo {
        width: 200px;
    }
}

.footer-nav {
    margin: 2.5rem 0;
    text-align: center;
    @media (min-width: 940px) {
        margin: 0 1rem;
        text-align: left;
    }
}

.footer-title {
    padding-bottom: 0.2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    font-size: 1.3rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
}

.footer-contacts {

}

.footer-contacts-link{
    display: inline-block;
}

.footer-contact {
    margin: 2.5rem 0;
    text-align: center;

    @media (min-width: 940px) {
        margin: 0 20px 2.5rem;
        text-align: left;
    }
}
