@import "vars.css";

.gallery {
    margin: 0 -1%;
}

.gallery-item {
    position: relative;
    width: 31.3%;
    padding: 31% 0 0 0;
    margin: 1%;
    overflow: hidden;
    background: var(--color-bg-dark);
    border-radius: 2px;
    overflow: hidden;
    color: var(--color-white);

    @media (--viewport-md) {
        width: 23%;
        padding: 23% 0 0 0;
    }
}

.gallery-item-link{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        background-color: var(--color-bg-dark-01);
        transition: background-color .2s ease-in-out;
    }

    &:focus,
    &:hover,
    &:visited {
        text-decoration: none;
        color: var(--color-white);
    }


    &:focus,
    &:hover {
        &:before {
            background-color: var(--color-bg-dark-04);
        }
        .gallery-item-zoom {
            opacity: 1;
        }
    }
}

.gallery-participant .gallery-item:first-child {
    width: 100%;
    margin-top: 0;
    padding: 100% 0 0 0;

    .gallery-item-zoom{
      font-size: 1.5rem;
    }
}

.gallery-participant .gallery-item.gallery-item-video{
    width: 100%;
    padding: 56.25% 0 0 0;
}
.gallery-item-video iframe{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.gallery-item-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.gallery-item-zoom {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 4;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    transform: translateX(-8px) translateY(-8px);
}

.gallery-item {

}
