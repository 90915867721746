@import "vars.css";

.participant_card {
    height: calc(100% - 30px);
    margin-bottom: 30px;
    border: none;
}

.participant_card-img-wrapper {
    position: relative;
    height: 365px;
    overflow: hidden;
    background: var(--color-bg-dark);
}

.participant_card-img-link {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s;
}

.participant_card:focus .participant_card-img-link,
.participant_card:hover .participant_card-img-link{
    background-color: rgba(0, 0, 0, 0);
}


.participant_card-img {
    position: relative;
    z-index: 1;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.participant_position{
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 2;
    color: #fff;
    font-size: 72px;
    line-height: 1;
    font-family: var(--font-family-sans-serif-poiret-one);
}

.participant_card-body {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.participant_card-body-info {
    flex-grow: 1000;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #a69e7b;
    color: var(--color-white);
    overflow: hidden;
}

.participant_card-body-info:visited,
.participant_card-body-info:focus,
.participant_card-body-info:hover {
    color: var(--color-white);
}

.participant_card-body-info-title {
    margin: 0;
    font-family: var(--font-family-sans-serif-pt-sans);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.participant_card-body-info-text {
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.participant_card-body-action {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100px;
    padding: 16px 0;
    background-color: #2c4493;
    text-align: center;
}

.participant_vote-count {
    margin: 8px 0 0 0;
    color: var(--color-white);
    font-family: var(--font-family-sans-serif-pt-sans);
    font-size: 14px;
}

.participant-voteActionBtn {
    display: inline-block;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}
