html,
body {
    font-size: 16px;
}

body {
    position: relative;
    min-height: 100vh;
    padding: 0;
    background-color: #f5eade;
}

.app-wrapper {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app-body {
    flex-grow: 1000;
    background-color: #ffffff;
}

.lead-small {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: normal;
    font-family: 'PT Sans', sans-serif;
}

.table-responsive{
    display: table !important;
    width: auto;
}

.auth-container {
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
}

@media (min-width: 340px) {

    .auth-container {
        background-color: transparent;
    }
}

.auth-form {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  background-color: #fff;
}

@media (min-width: 340px) {

    .auth-form {
        min-width: 290px;
        max-width: 400px;
    }
}

.btn-social-auth{
  margin: 10px 8px 0 0;
  text-decoration: none !important
}

.btn-social-auth:active,
  .btn-social-auth:hover{
    color: #ffffff;
}

.btn-social-auth__facebook{
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
  background-color: #3f51b5;
}

.btn-social-auth__vkontakte{
  padding-left: 9px;
  padding-right: 9px;
  color: #ffffff;
  background-color: #4f719b;
}

.contest-description {
    margin-bottom: 45px;
}

.about-text-block {
    margin-bottom: 24px
}

.about-text-block:last-child{
    margin-bottom: 0;
}

.about-col-even-order-1{
    order: 2;
}

@media (min-width: 540px) {

    .about-col-even-order-1{
        order: 1;
    }
}

.about-col-even-order-2{
    order: 1;
}

@media (min-width: 540px) {

    .about-col-even-order-2{
        order: 2;
    }
}

.about-col-img{
    margin-bottom: 24px;
}

@media (min-width: 540px) {

    .about-col-img{
        margin-bottom: 0;
    }
}

.steps {
	margin: 90px 0 18px 0;
}

.steps-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	margin: 0 -24px;
}

@media (min-width: 540px) {

    .steps-wrapper {
        flex-direction: row;
    }
}

@media (min-width: 960px) {

    .steps-wrapper {
        justify-content: space-between;
    }
}

.step {
	white-space: nowrap;
	margin: 0 24px 32px;
}

.step-value {
	margin: 0 0 10px;
	font-family: 'Poiret One', cursive;
	font-size: 72px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a69e7b;
	text-align: center;
}

.step-label {
	margin: 0 0 10px;
	font-size: 14px;
	color: #000000;
	text-align: center;
}

.step-date {
	color: #a69e7b;
	font-weight: 800;
	text-align: center;
}

.step-date-under {
	text-decoration: line-through;
}

.jury{
    margin: 20px 0 50px;
}

.jury-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -30px;
}

@media (max-width: 960px) {

    .jury-list {
        margin: 0 -10px;
    }
}

.jury-item {
    max-width: 220px;
    margin: 20px 30px 0;
}

.jury-list-title {
    margin: 0 0 30px 0;
}

.jury-item-photo {
    width: 182px;
    margin: 0 auto 12px;
    text-align: center;
    vertical-align: top;
    border-radius: 50%;
    border: 1px solid #a69e7b;
}

.jury-item-photo img {
        border-radius: 50%;
        border: 2px solid #fff;
    }

.jury-item-name {
    margin: 0 0 6px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #000000;
}

.jury-item-info {
    font-size: 14px;
    line-height: 1.42;
    text-align: center;
    color: #000000;
}

.footer {
    padding: 40px 0 0 0;
    padding: 2.5rem 0 0 0;
    background: url('../img/footer.jpg');
    background-position: center;
    background-size: cover;
    color: #a69e7b;
    font-family: 'Lato', sans-serif;
    font-size: 17.6px;
    font-size: 1.1rem;
}

.footer a{
        color: #fff;
        text-decoration: underline;
    }

.footer .nav-link{
        padding: 8px 0;
        padding: 0.5rem 0;
    }

@media (min-width: 940px) {

    .container-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
}

.footer-logo {
    text-align: center;
}

@media (min-width: 940px) {

    .footer-logo {
        text-align: left;
    }
}

.footer-logo .logo {
        width: 200px;
}

.footer-nav {
    margin: 40px 0;
    margin: 2.5rem 0;
    text-align: center;
}

@media (min-width: 940px) {

    .footer-nav {
        margin: 0 1rem;
        text-align: left;
    }
}

.footer-title {
    padding-bottom: 3.2px;
    padding-bottom: 0.2rem;
    margin-bottom: 16px;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
    font-size: 20.8px;
    font-size: 1.3rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
}

.footer-contacts {

}

.footer-contacts-link{
    display: inline-block;
}

.footer-contact {
    margin: 40px 0;
    margin: 2.5rem 0;
    text-align: center;
}

@media (min-width: 940px) {

    .footer-contact {
        margin: 0 20px 2.5rem;
        text-align: left;
    }
}

.logo {
	width: 100px;
	vertical-align: top;
}

.carousel-header .carousel:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000;
    background-color: rgba(0, 0, 0, .5);
}

.carousel-header .carousel .carousel-item {
            height: 340px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
}

@media (min-width: 768px) {

    .carousel-header .carousel .carousel-item {
        height: 440px;
    }
}

@media (min-width: 1024px) {

    .carousel-header .carousel .carousel-item {
        height: 540px;
    }
}

@media (min-width: 1600px) {

    .carousel-header .carousel .carousel-item {
        height: 750px;
    }
}

.carousel-header .carousel .carousel-caption {
            bottom: 0;
            left: 15px;
            right: 15px;
}

@media (min-width: 480px) {

    .carousel-header .carousel .carousel-caption {
        left: 15%;
        right: 15%;
    }
}

@media (min-width: 768px) {

    .carousel-header .carousel .carousel-caption {
        bottom: 15px;
    }
}

@media (min-width: 1024px) {

    .carousel-header .carousel .carousel-caption {
        bottom: 25px;
    }
}

@media (min-width: 1600px) {

    .carousel-header .carousel .carousel-caption {
        bottom: 60px;
    }
}

.carousel-header .carousel .carousel-caption h1 {
                margin-bottom: 8px;
                margin-bottom: 0.5rem;
                font-size: 24px;
                line-height: 1.4;
}

@media (min-width: 480px) {

    .carousel-header .carousel .carousel-caption h1 {
        font-size: 32px;
    }
}

.carousel-header .carousel .carousel-caption .caption-desc {
                margin-bottom: 32px;
                margin-bottom: 2rem;
                font-size: 18px;
}

@media (min-width: 480px) {

    .carousel-header .carousel .carousel-caption .caption-desc {
        font-size: 24px;
    }
}

.carousel-header .carousel .carousel-caption .btn {
                display: block;
}

.carousel-header .carousel .carousel-caption .btn:last-child {
    margin-top: 16px;
    margin-top: 1rem;
}

@media (min-width: 550px) {

    .carousel-header .carousel .carousel-caption .btn {
        display: inline-block;
    }

    .carousel-header .carousel .carousel-caption .btn:last-child {
        margin-top: 0;
        margin-left: 16px;
        margin-left: 1rem;
    }
}

.counters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    flex-direction: column;

    margin: 0 -30px 50px;
    padding: 15px 30px;
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

@media (min-width: 768px) {

    .counters {
        flex-direction: row;
    }
}

.counter {
    margin-bottom: 16px;
    text-align: center;
    align-self: center;
}

@media (min-width: 768px) {

    .counter {
        margin-bottom: 0;
    }
}

.counter-value {
    margin-bottom: 4px;
    font-family: 'Poiret One', cursive;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a69e7b;
    font-size: 38px;
}

@media (min-width: 768px) {

    .counter-value {
        font-size: 52px;
    }
}

@media (min-width: 1024px) {

    .counter-value {
        font-size: 72px;
    }
}

.counter-text {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
}

@media (min-width: 768px) {

    .counter-text {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {

    .counter-text {
        font-size: 18px;
    }
}

.partners-group {
    padding: 60px 0 0;
    border-bottom: 2px solid #d6d6d6;
}

.partners__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 32px;
    margin-bottom: 2rem;
}

.partners__list-item {
    display: block;
    margin: 32px;
}

.partners__list-item-img {
    max-width: 480px;
    max-height: 480px;
}

.partners_carousel{
    text-align: center;
}

.partners_carousel .partners__list-item{
        margin: 75px 0 45px;
    }

.partners_video{
    width: 100%;
    margin: 50px 0;
}

.partners_video iframe{
    width: 100%;
    height: 250px;
}

@media (min-width: 480px) {

    .partners_video iframe{
        height: 400px;
    }
}

@media (min-width: 768px) {

    .partners_video iframe{
        height: 550px;
    }
}

@media (min-width: 1024px) {

    .partners_video iframe{
        height: 620px;
    }
}

.partners__list-carousel {
    padding: 25px 25px 45px;
    background-color: #025171;
}

.partners__list-carousel .carousel{
        box-shadow: 0 30px 30px -20px rgba(40, 10, 53, .72);
    }

.partners__list-carousel .carousel-indicators{
        bottom: -40px;
    }

.profile-page {
    padding: 50px 0;
}

.profile-page__header {
    text-align: center;
}

.profile-page__header h3 {
        margin: 0 0 16px;
    }

@media (min-width: 600px) {

    .profile-page__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        vertical-align: middle;
        justify-content: space-between;
    }

    .profile-page__header h3 {
            margin: 0;
    }
}

.profile-info-item {
    margin: 0 0 16px;
}

.profile-info-item dt {
        vertical-align: center;
        margin: 0;
        padding: 0;
        font-weight: bold
    }

.profile-info-item dt:after {
    content: ':';
}

.profile-info-item dd {
        margin: 0;
        padding: 0;
        vertical-align: center;
    }

@media (min-width: 600px) {

    .profile-info-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        vertical-align: center;
    }

    .profile-info-item dt {
            width: 300px;
            margin: 0 10px 0 0;
            text-align: right;
    }
}

.profile-photo-actions {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}

.profile-photo-actions .btn {
        position: relative;
        margin-left: 6px;
    }

.profile-photo-delete-icon {
    color: #ea221d;
}

.profile-photo-btn-root-icon {
    color: #5cb85c;
}

.homepage-title{
    margin-top: 50px;
}

.participants_homepage{
    margin-bottom: 50px;
}

/* direct_uploads.css */

.direct-upload {
    display: inline-block;
    position: relative;
    padding: 2px 4px;
    margin: 0 3px 3px 0;
    border: 1px solid #000000;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 3px;
    font-size: 11px;
    line-height: 13px;
}

.direct-upload--pending {
    opacity: 0.6;
}

.direct-upload__progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    background: #0076ff;
    transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
    transform: translate3d(0, 0, 0);
}

.direct-upload--complete .direct-upload__progress {
    opacity: 0.4;
}

.direct-upload--error {
    border-color: red;
}

input[type=file][data-direct-upload-url][disabled] {
    display: none;
}

.participant_card {
    height: calc(100% - 30px);
    margin-bottom: 30px;
    border: none;
}

.participant_card-img-wrapper {
    position: relative;
    height: 365px;
    overflow: hidden;
    background: #2a2452;
}

.participant_card-img-link {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-decoration: none;
    background-color: #000000;
    background-color: rgba(0, 0, 0, .3);
    transition: background-color 0.2s;
}

.participant_card:focus .participant_card-img-link,
.participant_card:hover .participant_card-img-link{
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0);
}

.participant_card-img {
    position: relative;
    z-index: 1;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.participant_position{
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 2;
    color: #fff;
    font-size: 72px;
    line-height: 1;
    font-family: 'Poiret One', cursive;
}

.participant_card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.participant_card-body-info {
    flex-grow: 1000;
    flex-shrink: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #a69e7b;
    color: #ffffff;
    overflow: hidden;
}

.participant_card-body-info:visited,
.participant_card-body-info:focus,
.participant_card-body-info:hover {
    color: #ffffff;
}

.participant_card-body-info-title {
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.participant_card-body-info-text {
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.participant_card-body-action {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100px;
    padding: 16px 0;
    background-color: #2c4493;
    text-align: center;
}

.participant_vote-count {
    margin: 8px 0 0 0;
    color: #ffffff;
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

.participant-voteActionBtn {
    display: inline-block;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

.participantPage-head{
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
}

.participantPage-name {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
}

.participantPage-position {
    margin: 0;
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
    text-align: center;
    font-weight: bold;
}

.participantPage-listData {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: baseline;
    margin: 0 0 8px;
}

.participantPage-listData dt {
        margin: 0 8px 0 0;
        color: #666666;
        font-weight: lighter;
        font-size: 14.4px;
        font-size: 0.9rem
    }

.participantPage-listData dt:after{
    content: ':';
}

.participantPage-listData dd {
        margin: 0;
    }

.participantPage-listData.participantPage-listDataColumn{
    flex-direction: column;
}

.participantPage-listData.participantPage-listDataColumn dt{
            margin: 0 0 8px;
}

.gallery {
    margin: 0 -1%;
}

.gallery-item {
    position: relative;
    width: 31.3%;
    padding: 31% 0 0 0;
    margin: 1%;
    overflow: hidden;
    background: #2a2452;
    border-radius: 2px;
    overflow: hidden;
    color: #ffffff;
}

@media (min-width: 768px) {

    .gallery-item {
        width: 23%;
        padding: 23% 0 0 0;
    }
}

.gallery-item-link{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%
}

.gallery-item-link:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    background-color: #000000;
    background-color: rgba(0, 0, 0, .1);
    transition: background-color .2s ease-in-out;
}

.gallery-item-link:focus,
    .gallery-item-link:hover,
    .gallery-item-link:visited {
    text-decoration: none;
    color: #ffffff;
}

.gallery-item-link:focus:before, .gallery-item-link:hover:before {
    background-color: #000000;
    background-color: rgba(0, 0, 0, .4);
}

.gallery-item-link:focus .gallery-item-zoom, .gallery-item-link:hover .gallery-item-zoom {
            opacity: 1;
}

.gallery-participant .gallery-item:first-child {
    width: 100%;
    margin-top: 0;
    padding: 100% 0 0 0;
}

.gallery-participant .gallery-item:first-child .gallery-item-zoom{
      font-size: 24px;
      font-size: 1.5rem;
    }

.gallery-participant .gallery-item.gallery-item-video{
    width: 100%;
    padding: 56.25% 0 0 0;
}

.gallery-item-video iframe{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.gallery-item-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.gallery-item-zoom {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 4;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    transform: translateX(-8px) translateY(-8px);
}

.gallery-item {

}

.share-social {
    margin: 24px 0;
    margin: 1.5rem 0;
}

.share-social-title {
    margin-bottom: 8px;
    font-size: 19.2px;
    font-size: 1.2rem;
    font-weight: 900
}

.share-social-title:after {
    content: ':';
}

.share-social-actions .ya-share2__container_size_m .ya-share2__icon {
            height: 40px;
            width: 40px;
        }

.shop-banner {
    background-color: #ffcc00;
    color: #000000;
}

.shop-banner-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
}

@media (min-width: 768px) {

    .shop-banner-body {
        flex-direction: row;
    }
}

.shop-banner-title {
    margin: 0 0 12px 0;
    font-size: 16px;
    text-transform: uppercase;
}

@media (min-width: 768px) {

    .shop-banner-title {
        margin: 0 12px 0 0;
    }
}

.shop-title {
    margin-top: 50px;
}

.shop-description {

}

.order-tabs {
    margin: 48px 0;
}

.order-tabsTitle {
    margin: 0 0 24px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.21;
}

.order-tabsTabBody {
    padding: 16px 0 0 0;
}

.order-tabsTabBody ul {
        margin: 0 0 24px;
        padding: 0 0 0 16px;
    }

.order_personal_confirm {
    display: inline-block;
    margin: 0;
}

.order-formAction {
    margin: 16px 0;
    text-align: center;
}

.order-subtitle {
    margin: 64px 0;
    text-align: center;
}

.order-subtitle-2 {
    text-align: center;
}

.packages {
    padding: 0 0 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

@media (min-width: 768px) {

    .packages {
        flex-direction: row;
        align-items: center;
    }
}

.package {
    position: relative;
    height: 205px;
    width: 300px;
    margin: 0 0 64px;
    background-color: #f5eade

}

.package:before {
    background: #fff;
    border-radius: 50%;
    box-shadow: 300px 0 0 0 #fff,
        0 205px 0 0 #fff,
        300px 205px 0 0 #fff,
        0 40px 0 -20px #fff,
        0 60px 0 -20px #fff,
        0 80px 0 -20px #fff,
        0 100px 0 -20px #fff,
        0 120px 0 -20px #fff,
        0 140px 0 -20px #fff,
        0 160px 0 -20px #fff,
        300px 40px 0 -20px #fff,
        300px 60px 0 -20px #fff,
        300px 80px 0 -20px #fff,
        300px 100px 0 -20px #fff,
        300px 120px 0 -20px #fff,
        300px 140px 0 -20px #fff,
        300px 160px 0 -20px #fff;
    content: "";
    display: block;
    height: 50px;
    left: -25px;
    position: absolute;
    top: -25px;
    width: 50px;
}

@media (min-width: 768px) {

    .package {
        margin: 0 32px 64px;
        width: 350px;
    }

    .package:before {
        box-shadow: 350px 0 0 0 #fff,
            0 205px 0 0 #fff,
            350px 205px 0 0 #fff,
            0 40px 0 -20px #fff,
            0 60px 0 -20px #fff,
            0 80px 0 -20px #fff,
            0 100px 0 -20px #fff,
            0 120px 0 -20px #fff,
            0 140px 0 -20px #fff,
            0 160px 0 -20px #fff,
            350px 40px 0 -20px #fff,
            350px 60px 0 -20px #fff,
            350px 80px 0 -20px #fff,
            350px 100px 0 -20px #fff,
            350px 120px 0 -20px #fff,
            350px 140px 0 -20px #fff,
            350px 160px 0 -20px #fff;
    }
}

.package-wrapper {
    text-align: center;
    padding: 16px 32px;
    line-height: 1.3;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px
}

.package-wrapper:before,
    .package-wrapper:after {
    background: #333;
    color: #aaa;
    display: inline-block;
    font-size: 16px;
    left: 50%;
    padding: 0 10px;
    position: absolute;
}

.package-title {
    margin: 0;
    font-size: 20px;
    font-weight: 800;
}

.package-desc {
    margin: 0 0 14px;
}

.package-price {
    margin: 0 0 14px;
    font-size: 20px;
    font-weight: 800;
}

.package-more {
    margin: 0 0 16px;
}

.champion {

}

.champion .participant_card-img-wrapper {
    height: 510px;
}

.champion-head{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-end;

    min-height: 48px;
    margin-bottom: 20px;
}

.champion-head h5{
        margin-bottom: 0;
    }
