@import "vars.css";

.profile-page {
    padding: 50px 0;
}

.profile-page__header {
    text-align: center;

    h3 {
        margin: 0 0 16px;
    }

    @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        vertical-align: middle;
        justify-content: space-between;

        h3 {
            margin: 0;
        }
    }
}

.profile-info-item {
    margin: 0 0 16px;

    dt {
        vertical-align: center;
        margin: 0;
        padding: 0;
        font-weight: bold;

        &:after {
            content: ':';
        }
    }

    dd {
        margin: 0;
        padding: 0;
        vertical-align: center;
    }

    @media (min-width: 600px) {
        display: flex;
        vertical-align: center;

        dt {
            width: 300px;
            margin: 0 10px 0 0;
            text-align: right;
        }
    }
}


.profile-photo-actions {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;

    .btn {
        position: relative;
        margin-left: 6px;
    }
}

.profile-photo-delete-icon {
    color: var(--hover-color);
}

.profile-photo-btn-root-icon {
    color: var(--brand-success);
}
