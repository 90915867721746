.champion {

}

.champion .participant_card-img-wrapper {
    height: 510px;
}

.champion-head{
    display: flex;
    align-items: flex-end;

    min-height: 48px;
    margin-bottom: 20px;

    h5{
        margin-bottom: 0;
    }
}
