@import "vars.css";

.shop-banner {
    background-color: #ffcc00;
    color: var(--color-dark);
}

.shop-banner-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;

    @media (--viewport-md) {
        flex-direction: row;
    }
}

.shop-banner-title {
    margin: 0 0 12px 0;
    font-size: 16px;
    text-transform: uppercase;

    @media (--viewport-md) {
        margin: 0 12px 0 0;
    }
}

.shop-title {
    margin-top: 50px;
}

.shop-description {

}


.order-tabs {
    margin: 48px 0;
}

.order-tabsTitle {
    margin: 0 0 24px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.21;
}

.order-tabsTabBody {
    padding: 16px 0 0 0;

    ul {
        margin: 0 0 24px;
        padding: 0 0 0 16px;
    }
}

.order_personal_confirm {
    display: inline-block;
    margin: 0;
}

.order-formAction {
    margin: 16px 0;
    text-align: center;
}

.order-subtitle {
    margin: 64px 0;
    text-align: center;
}

.order-subtitle-2 {
    text-align: center;
}

.packages {
    padding: 0 0 64px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    @media (--viewport-md) {
        flex-direction: row;
        align-items: center;
    }
}

.package {
    position: relative;
    height: 205px;
    width: 300px;
    margin: 0 0 64px;
    background-color: #f5eade;

    &:before {
        background: #fff;
        border-radius: 50%;
        box-shadow: 300px 0 0 0 #fff,
        0 205px 0 0 #fff,
        300px 205px 0 0 #fff,
        0 40px 0 -20px #fff,
        0 60px 0 -20px #fff,
        0 80px 0 -20px #fff,
        0 100px 0 -20px #fff,
        0 120px 0 -20px #fff,
        0 140px 0 -20px #fff,
        0 160px 0 -20px #fff,
        300px 40px 0 -20px #fff,
        300px 60px 0 -20px #fff,
        300px 80px 0 -20px #fff,
        300px 100px 0 -20px #fff,
        300px 120px 0 -20px #fff,
        300px 140px 0 -20px #fff,
        300px 160px 0 -20px #fff;
        content: "";
        display: block;
        height: 50px;
        left: -25px;
        position: absolute;
        top: -25px;
        width: 50px;
    }

    @media (--viewport-md) {
        margin: 0 32px 64px;
        width: 350px;

        &:before {
            box-shadow: 350px 0 0 0 #fff,
            0 205px 0 0 #fff,
            350px 205px 0 0 #fff,
            0 40px 0 -20px #fff,
            0 60px 0 -20px #fff,
            0 80px 0 -20px #fff,
            0 100px 0 -20px #fff,
            0 120px 0 -20px #fff,
            0 140px 0 -20px #fff,
            0 160px 0 -20px #fff,
            350px 40px 0 -20px #fff,
            350px 60px 0 -20px #fff,
            350px 80px 0 -20px #fff,
            350px 100px 0 -20px #fff,
            350px 120px 0 -20px #fff,
            350px 140px 0 -20px #fff,
            350px 160px 0 -20px #fff;
        }

    }

}

.package-wrapper {
    text-align: center;
    padding: 16px 32px;
    line-height: 1.3;
    font-family: var(--font-family-sans-serif-lato);
    font-weight: 500;
    font-size: 16px;

    &:before,
    &:after {
        background: #333;
        color: #aaa;
        display: inline-block;
        font-size: 16px;
        left: 50%;
        padding: 0 10px;
        position: absolute;
    }
}

.package-title {
    margin: 0;
    font-size: 20px;
    font-weight: 800;
}

.package-desc {
    margin: 0 0 14px;
}

.package-price {
    margin: 0 0 14px;
    font-size: 20px;
    font-weight: 800;
}

.package-more {
    margin: 0 0 16px;
}

