@import "vars.css";

.counters {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    flex-direction: column;

    margin: 0 -30px 50px;
    padding: 15px 30px;
    font-family: var(--font-family-sans-serif-lato);
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    @media (--viewport-md) {
        flex-direction: row;
    }
}

.counter {
    margin-bottom: 16px;
    text-align: center;
    align-self: center;

    @media (--viewport-md) {
        margin-bottom: 0;
    }
}

.counter-value {
    margin-bottom: 4px;
    font-family: var(--font-family-sans-serif-poiret-one);
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a69e7b;
    font-size: 38px;

    @media (--viewport-md) {
        font-size: 52px;
    }

    @media (--viewport-lg) {
        font-size: 72px;
    }
}

.counter-text {
    font-family: var(--font-family-sans-serif-lato);
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
    text-transform: uppercase;

    @media (--viewport-md) {
        font-size: 18px;
    }

    @media (--viewport-lg) {
        font-size: 18px;
    }
}
