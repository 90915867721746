$font-family-sans-serif: 'Lato', sans-serif;
$headings-margin-bottom: 2rem;

$font-size-base: 1rem;
$lead-font-size: ($font-size-base * 1.5) !default;

//$border-radius: .1rem !default;
//$border-radius-lg: .1rem !default;
//$border-radius-sm: .1rem !default;

$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 2rem !default;

$grid-breakpoints: (
		xs: 0,
		sm: 480px,
		md: 768px,
		lg: 1024px
);

$card-border-radius: 2px;

@import '~bootstrap/scss/bootstrap';

.container {
	max-width: 100%;

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media-breakpoint-up(xl) {
		max-width: 1140px;
	}
}
