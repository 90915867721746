@import "vars.css";

html,
body {
    font-size: var(--base-font-size);
}

body {
    position: relative;
    min-height: 100vh;
    padding: 0;
    background-color: var(--body-bg-color);
}

.app-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app-body {
    flex-grow: 1000;
    background-color: var(--app-body-bg-color);
}

.lead-small {
    font-size: 1.25rem;
    font-weight: normal;
    font-family: var(--font-family-sans-serif-pt-sans);
}

.table-responsive{
    display: table !important;
    width: auto;
}
