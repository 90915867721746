@import "vars.css";

.participantPage-head{
    text-align: center;
    font-family: var(--font-family-sans-serif-pt-sans);
    font-size: 20px;
}

.participantPage-name {
    font-family: var(--font-family-sans-serif-lato);
    font-weight: 900;
}

.participantPage-position {
    margin: 0;
    font-size: 20px;
    font-family: var(--font-family-sans-serif-pt-sans);
    text-align: center;
    font-weight: bold;
}

.participantPage-listData {
    display: flex;
    align-items: baseline;
    margin: 0 0 8px;

    dt {
        margin: 0 8px 0 0;
        color: var(--color-dark-6);
        font-weight: lighter;
        font-size: 0.9rem;

        &:after{
            content: ':';
        }
    }

    dd {
        margin: 0;
    }

    &.participantPage-listDataColumn{
        flex-direction: column;

        dt{
            margin: 0 0 8px;
        }
    }
}