.auth-container {
  min-height: 100vh;
  width: 100%;
  background-color: transparent;

  @media (min-width: 340px) {
    background-color: transparent;
  }
}

.auth-form {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  background-color: #fff;

  @media (min-width: 340px) {
    min-width: 290px;
    max-width: 400px;
  }
}

.btn-social-auth{
  margin: 10px 8px 0 0;
  text-decoration: none !important;

  &:active,
  &:hover{
    color: #ffffff;
  }
}

.btn-social-auth__facebook{
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
  background-color: #3f51b5;
}
.btn-social-auth__vkontakte{
  padding-left: 9px;
  padding-right: 9px;
  color: #ffffff;
  background-color: #4f719b;
}
