@import "vars.css";

.contest-description {
    margin-bottom: 45px;
}

.about-text-block {
    margin-bottom: 24px;

    &:last-child{
        margin-bottom: 0;
    }
}

.about-col-even-order-1{
    order: 2;

    @media (--media-breakpoint-up-sm) {
        order: 1;
    }
}
.about-col-even-order-2{
    order: 1;

    @media (--media-breakpoint-up-sm) {
        order: 2;
    }
}

.about-col-img{
    margin-bottom: 24px;
    @media (--media-breakpoint-up-sm) {
        margin-bottom: 0;
    }
}
