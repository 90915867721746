@import "vars.css";

.steps {
	margin: 90px 0 18px 0;
}

.steps-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	margin: 0 -24px;

	@media (--media-breakpoint-up-sm) {
		flex-direction: row;
	}
	@media (--media-breakpoint-up-lg) {
		justify-content: space-between;
	}
}

.step {
	white-space: nowrap;
	margin: 0 24px 32px;
}

.step-value {
	margin: 0 0 10px;
	font-family: var(--font-family-sans-serif-poiret-one);
	font-size: 72px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--color-brown);
	text-align: center;
}

.step-label {
	margin: 0 0 10px;
	font-size: 14px;
	color: var(--color-dark);
	text-align: center;
}

.step-date {
	color: var(--color-brown);
	font-weight: 800;
	text-align: center;
}
.step-date-under {
	text-decoration: line-through;
}
