@import "vars.css";

.carousel-header {
    .carousel {
        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .5);
        }

        .carousel-item {
            height: 340px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (--viewport-md) {
                height: 440px;
            }
            @media (--viewport-lg) {
                height: 540px;
            }
            @media (min-width: 1600px) {
                height: 750px;
            }
        }

        .carousel-caption {
            bottom: 0;
            left: 15px;
            right: 15px;

            @media (--viewport-sm) {
                left: 15%;
                right: 15%;
            }

            @media (--viewport-md) {
                bottom: 15px;
            }

            @media (--viewport-lg) {
                bottom: 25px;
            }

            @media (min-width: 1600px) {
                bottom: 60px;
            }

            h1 {
                margin-bottom: 0.5rem;
                font-size: 24px;
                line-height: 1.4;

                @media (--viewport-sm) {
                    font-size: 32px;
                }
            }

            .caption-desc {
                margin-bottom: 2rem;
                font-size: 18px;

                @media (--viewport-sm) {
                    font-size: 24px;
                }
            }

            .btn {
                display: block;

                &:last-child {
                    margin-top: 1rem;
                }

                @media (min-width: 550px) {
                    display: inline-block;

                    &:last-child {
                        margin-top: 0;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }

}
