@import "vars.css";

.partners-group {
    padding: 60px 0 0;
    border-bottom: 2px solid #d6d6d6;
}

.partners__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 2rem;
}

.partners__list-item {
    display: block;
    margin: 32px;
}

.partners__list-item-img {
    max-width: 480px;
    max-height: 480px;
}

.partners_carousel{
    text-align: center;

    .partners__list-item{
        margin: 75px 0 45px;
    }
}

.partners_video{
    width: 100%;
    margin: 50px 0;
}

.partners_video iframe{
    width: 100%;
    height: 250px;

    @media (--viewport-sm) {
        height: 400px;
    }
    @media (--viewport-md) {
        height: 550px;
    }
    @media (--viewport-lg) {
        height: 620px;
    }
}
.partners__list-carousel {
    padding: 25px 25px 45px;
    background-color: #025171;

    .carousel{
        box-shadow: 0 30px 30px -20px rgba(40,10,53,.72);
    }

    .carousel-indicators{
        bottom: -40px;
    }
}
