.share-social {
    margin: 1.5rem 0;
}

.share-social-title {
    margin-bottom: 8px;
    font-size: 1.2rem;
    font-weight: 900;

    &:after {
        content: ':';
    }
}

.share-social-actions {
    .ya-share2__container_size_m {
        .ya-share2__icon {
            height: 40px;
            width: 40px;
        }
    }
}
